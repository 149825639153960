import React from 'react'
import styled from 'styled-components'
import { deviceGreaterThan } from '../../styles/breakpoints';

export const AppDownloadContainer = styled.div`
    position: relative;
`
export const AppDownload = styled.div`
    position: absolute;
    bottom: 50px;
    z-index: 200;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    @media ${deviceGreaterThan.tablet}{
        display:none;
    }
`;

export const Wrapper = styled.div`
    border-radius: 4.2px;
    box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    display: flex;
    padding: 12px 0px;
    justify-content: center;
`;

export const IconWrapper = styled.div`
    & > img {
        margin-left: 10px;
    }
`

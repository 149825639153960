import React from 'react'
import styled from 'styled-components'
import { deviceLessThan } from '../../styles/breakpoints';

const background_pattern = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1600027715/chqbookWeb/homepage/get-started-pattern.png';
const background_pattern_mobile = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1600031309/chqbookWeb/less50kb/get-started-pattern-mobile.png'


export const GetStartedContainer = styled.div`
    background: url(${background_pattern}) repeat;
    background-color: #F7F7F7;
    width: 100%;
    padding: 40px 0 22px 0;
    @media ${deviceLessThan.tablet}{
        padding: 40px 20px;
        background: url(${background_pattern_mobile}) #F7F7F7 repeat;
    }
`;

export const GetStartedWrapper = styled.div`
    width: 75%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    & > div:last-child{
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    @media ${deviceLessThan.laptop}{
        width: 95%
    }
    @media ${deviceLessThan.tablet}{
        flex-direction: column-reverse;
        width: 100%
    }
`;

import React from 'react'
import PropTypes from 'prop-types'
import {
    ContentWrapper,
    ProductContainer,
    ProductWrapper,
    IconHeading,
    CenterImageWrapper,
    MockupCircle,
    ImageWrapper,
    TopImageWrapper,
    SectionBottomLine
} from './Components'
import { Image, ImageContainer } from '../common/ui/Image';
import Text from '../common/ui/Text';
import Heading from '../common/ui/Heading';
import CutomButton from '../common/ui/CutomButton'
import { navigate } from 'gatsby'

const Product = props => {
    const {
        product = {}
    } = props;

    const onButtonClick = () => {
        try {
            if (typeof (product.link) == "string" && product.link.includes('http')) {
                window.open(product.link, '_blank');
            }
            else if (product.external) {
                window.open(process.env.BASE_URI + product.link, '_self')
            } else {
                navigate(product.link);
            }
        } catch (err) {
            console.log('break', err)
        }
    }

    return (
        <ProductContainer revert={product.revert}>
            <ProductWrapper revert={product.revert}>
                <ContentWrapper noBack={true} revert={product.revert}>
                    <IconHeading>
                        <Image src={product.iconSrc}
                            width='20px'
                        />
                                &nbsp;&nbsp;
                            <Text size='xl' weight='bold' opacity='0.9'>{product.name}</Text>
                    </IconHeading>
                    <Heading
                        lineHeight='1.29'
                        size='md'
                        weight='bold'
                        color='MEDIUM_BLACK'
                        spacing='-0.2px'
                    >{product.heading}</Heading>
                    <Image src='/images/red-underline.svg' width='313px' />
                    <Text lineHeight='1.78' size='lg' color='LIGHTER_GREY' margin='24px 0 40px 0'>{product.excerpt}</Text>
                    <CutomButton sdisplay='none' onClick={onButtonClick}>
                        {product.btnLabel}
                        {
                            product.appIcons &&
                            <>
                                <ImageContainer width='13px'>
                                    <Image src='/images/icons/playstore-header-cta.png' />
                                </ImageContainer>
                                {/* <ImageContainer width='13px'>
                                    <Image src='/images/icons/apple-header-cta.png' />
                                </ImageContainer> */}

                            </>
                        }
                    </CutomButton>
                </ContentWrapper>
                <ContentWrapper>
                    <CenterImageWrapper>
                        <MockupCircle revert={product.revert} />
                        <ImageWrapper>
                            <Image
                                {...product.centerImg}
                                className='center-image'
                                width='234px'
                                style={product.centerImgStyle ? product.centerImgStyle : { height: '453px', width: 'auto', left: product.leftStyle ? product.leftStyle : '0%'}}
                            />
                            {
                                product.showBottomLine &&
                                <SectionBottomLine sdisplay='none' />
                            }
                            {/* <TopImageWrapper>
                                <Image src={product.topImg} width='192px' />
                                <Image src={product.bottomImg} width='164px' />
                            </TopImageWrapper> */}
                            <CutomButton
                                display='none'
                                sdisplay='block'
                                width='100%'
                                labelwidth='200px'
                                labelmargin='0 auto'
                                transform='translateY(-40px)'
                                onClick={onButtonClick}
                            >
                                {product.btnLabel}
                                {
                                    product.appIcons &&
                                    <>
                                        <ImageContainer width='13px'>
                                            <Image src='/images/icons/playstore-header-cta.png' />
                                        </ImageContainer>
                                        {/* <ImageContainer width='13px'>
                                            <Image src='/images/icons/apple-header-cta.png' />
                                        </ImageContainer> */}
                                    </>
                                }
                            </CutomButton>
                        </ImageWrapper>
                    </CenterImageWrapper>
                </ContentWrapper>
            </ProductWrapper>
        </ProductContainer>
    )
}

Product.propTypes = {

}

export default Product

import React from 'react'
import PropTypes from 'prop-types'
import { FeaturedImage, BlogContainer, BlogWrapper, ContentWrapper } from './Component'
import { ImageContainer } from '../common/ui/Image'
import Tag from '../common/ui/Tag'
import Heading from '../common/ui/Heading'
import Text from '../common/ui/Text'
import CustomLink from '../common/ui/CustomLink'
import { getOptimizedImageUrl } from '../../utils/helpers'

const Blog = props => {

    const {
        blog = {}
    } = props;

    return (
        <BlogContainer margin={props.containerMargin}>
            <Heading
                align='center'
                margin='0px 0 64px 0'
                size='sm'
                color='MEDIUM_BLACK'
                sMargin='24px 0 24px 0'
            >{blog.meta?.heading}
            </Heading>
            <BlogWrapper>
                {
                    blog.blogs instanceof Array &&
                    blog.blogs.map((item, i, arr) => {
                        return (
                            <ContentWrapper
                                key={i}
                                flex={i == 0 ? '100%' : '33%'}
                                flexDirection={i == 0 ? 'row' : 'column'}
                                sFlexDirection={i == 0 ? 'column' : 'row'}
                                padding={i == 0 || i == arr.length - 1 ? undefined : '0 26px 0 0'}
                                lastChildWidth={i == 0 ? '50%' : '100%'}
                                lastChildPadding={i == 0 ? '25px 0 0 25px' : '25px 0 0 0'}
                            >
                                <ImageContainer
                                    width={i == 0 ? '50%' : '100%'}
                                    sWidth={i == 0 ? '100%' : '70%'}
                                >
                                    <CustomLink
                                        to={`/${item.node.slug}`}
                                        target='_blank'
                                    >
                                        <FeaturedImage
                                            height={i == 0 ? '330px' : '215px'}
                                            sHeight={i == 0 ? '144px' : '100px'}
                                            src={getOptimizedImageUrl(item.node.feature_image)}
                                        />
                                    </CustomLink>
                                </ImageContainer>
                                <div>
                                    <Tag
                                        size='xs'
                                        color='TAG'
                                        weight='bold'
                                        backgroundColor='#fef4f4'
                                        padding='6px'
                                        width="max-content"
                                        margin='0 0 12px 0'
                                        mMargin='0'
                                    >{item.node.primary_tag?.name.toUpperCase()}
                                    </Tag>
                                    <CustomLink
                                        to={`/${item.node.slug}`}
                                        target='_blank'
                                    >
                                        <Heading
                                            size='xxxs'
                                            color='DARK_GREY'
                                            weight='normal'
                                            lineHeight='1.41'
                                            margin='0 0 10px 0'
                                        >
                                            {item.node.title}
                                        </Heading>
                                    </CustomLink>
                                    <Text
                                        color='MEDIUM_GREY'
                                        sDisplay={i == 0 ? 'initial' : 'none'}
                                    >
                                        {item.node.custom_excerpt}
                                        <CustomLink
                                            to={`/${item.node.slug}`}
                                            target='_blank'
                                            color='MEDIUM_GREY'
                                            display='initial'
                                            size='md'
                                            weight='bold'
                                        >
                                            ...read more
                                            </CustomLink>
                                    </Text>
                                </div>
                            </ContentWrapper>
                        );
                    })
                }
            </BlogWrapper>
            <Text
                align="center"
                margin='0 0 50px 0'
                sMargin='0 0 20px 0'
            >
                <CustomLink
                    size="md"
                    weight="large"
                    align="center"
                    color="CHQBOOK_BLUE"
                    letterSpacing="0.62px"
                    target='_blank'
                    to={blog.meta?.link}
                >
                    SEE MORE
                </CustomLink>
            </Text>
        </BlogContainer>
    )
}

Blog.propTypes = {

}

export default Blog

import React from 'react'
import PropTypes from 'prop-types'
import { CardWrapper, CardExcerpt } from './Components'
import { Image, ImageContainer } from '../common/ui/Image'
import Heading from '../common/ui/Heading'
import Text from '../common/ui/Text'

const Card = ({ card = {} }) => {
    return (
        <CardWrapper>
            <ImageContainer width='100%' sWidth='30%' textAlign='center'>
                <Image {...card.imgSrc} width='55px' height='55px' />
            </ImageContainer>
            <div>
                <Heading
                    lineHeight='1.11'
                    size='xxs'
                    margin='0 0 25px 0'
                    padding='0 15px'
                    sMargin='0 0 10px 0'
                    sPadding='0px'
                    sAlign='start'
                >{card.heading}</Heading>
                {
                    card.badge &&
                    <CardExcerpt>
                        <span>
                            <Image
                                src='https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1600541204/chqbookWeb/0-5kb/badge_ico.png'
                                width='14px'
                            />
                        </span>
                        &nbsp;
                        <span>{card.badge}</span>
                    </CardExcerpt>
                }
            </div>
        </CardWrapper>
    )
}

Card.propTypes = {

}

export default Card

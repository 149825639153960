import React from 'react'
import {
    FootText,
    Block,
    BlockRow
} from './Components';
import Heading from '../common/ui/Heading'
import Text from '../common/ui/Text'
import { StaticQuery, graphql } from 'gatsby'
import { getJsonFromString } from '../../utils/helpers'

const BannerInfoContainer = ({ data, ...props }) => {

    let componentData = data.allGhostPage.edges[0]?.node.plaintext;
    const sanitizedData = getJsonFromString(componentData);

    return (
        <FootText {...props}>
            {
                sanitizedData instanceof Array && sanitizedData.map((item, i) => {
                    return (
                        <Block key={i}>
                            <BlockRow
                                jContent={props.iconjContent}
                            >
                                <div>
                                    <img src={item.icon} width={props.iconWidth ? props.iconWidth : '24px'} style={{ margin:"0px 0px 3px 0px" }}/>
                                </div>
                                <Heading
                                    size='s'
                                    alignSelf='center'
                                >
                                    {item.title}
                                </Heading>
                            </BlockRow>
                            <Text size='sm'color='MEDIUM_BLACK' opacity='0.6'>{item.subtitle}</Text>
                        </Block>
                    )
                })
            }
        </FootText>
    )
}

BannerInfoContainer.propTypes = {

}

const BannerInfo = props => (
    <StaticQuery
        query={graphql`
        query {
            allGhostPage(
                filter: {
                    tags: {
                        elemMatch: {
                            name: {
                                eq: "#banner-info"
                                }
                            }
                        }
                    }
                ) {
                 edges{
                     node{
                         plaintext
                         }
                    }
                }
            }
        `}
        render={data => <BannerInfoContainer data={data} {...props} />}
    />
)

export default BannerInfo;

import React from 'react'
import PropTypes from 'prop-types'
import { MediaContainer, MediaWrapper, MediaCards } from './Components'
import Heading from '../common/ui/Heading'
import Card from './Card'
import CustomLink from '../common/ui/CustomLink'
import Text from '../common/ui/Text'

const MediaMentions = ({ data }) => {
    return (
        <MediaContainer>
            <MediaWrapper>
                <Heading padding='0 0 50px 0' size='sm' color='MEDIUM_BLACK' align='center'>{data.title}</Heading>
                <MediaCards>
                    {
                        data.news instanceof Array && data.news.filter(item => item.isFeatured).map((item, i) => {
                            if (i < 3) {
                                return (
                                    <Card news={item} key={i} />
                                )
                            }
                        })
                    }
                </MediaCards>
                <Text
                    align="center"
                    margin='20px 0 0 0'
                    sMargin='0 0 20px 0'
                >
                    <CustomLink
                        size="md"
                        weight="large"
                        align="center"
                        color="CHQBOOK_BLUE"
                        letterSpacing="0.62px"
                        to='/media-center/news'
                    >
                        READ MORE NEWS
                </CustomLink>
                </Text>
            </MediaWrapper>
        </MediaContainer>

    )
}

MediaMentions.propTypes = {

}

export default MediaMentions

import React from 'react'
import PropTypes from 'prop-types'
import { MediaCardContainer, LogoDateContainer, ReadMore } from './Components'
import { Image, ImageContainer } from '../common/ui/Image'
import Text from '../common/ui/Text'
import Heading from '../common/ui/Heading'

const Card = ({ news }) => {
    return (
        <MediaCardContainer>
            <LogoDateContainer>
                <ImageContainer imgSelfAlign='center'>
                    <Image src={news.icon} width={news.width} />
                </ImageContainer>
                <Text size='sm' color='MEDIUM_BLACK'>{news.date}</Text>
            </LogoDateContainer>
            <div />
            <Heading lineHeight='1.4' size='xxs' weight='normal' margin='0 0 13px 0' sMargin='0 0 8px 0'>{news.title}</Heading>
            <div>
                <Text size='md' color='MEDIUM_GREY' display='contents' sDisplay='none'>{news.excerpt}</Text>
                <Text size='md' color='MEDIUM_GREY' display='none' sDisplay='contents'>{news.excerpt_mobile}</Text>
                <ReadMore><a className='read-more' href={news.link} target='_blank'>...read more</a></ReadMore>
            </div>
        </MediaCardContainer>
    )
}

Card.propTypes = {

}

export default Card

import styled from 'styled-components';
import { deviceLessThan } from '../../styles/breakpoints';


export const BlogContainer = styled.div`
    width: 75%;
    max-width: 1200px;
    margin:${props => props.margin ? props.margin : "0px auto"};
    @media ${deviceLessThan.laptop}{
        width:95%;
    };
`;

export const ContentWrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction: ${props => props.flexDirection};
    flex: ${props => props.flex ? props.flex : '33%'};
    margin-bottom: 55px;
    padding: ${props => props.padding};
    & > div:last-child{
        width: ${props => props.lastChildWidth};
        padding: ${props => props.lastChildPadding};
    }

    @media ${deviceLessThan.tablet}{
        flex-direction: ${props => props.sFlexDirection};
        margin-bottom: 25px;
        & > div:last-child{
        width: 100%;
        padding: 0 0 0 12px;
    }
    };
`;

export const FeaturedImage = styled.div`
    margin: 0 0 10px 0;
    width: auto;
    height: ${props => props.height ? props.height : '215px'};
    background: var(--color-bg) no-repeat center center;
    background-size: cover;
    background-image: url(${props => props.src && props.src});
    @media ${deviceLessThan.tablet}{
        height: ${props => props.sHeight ? props.sHeight : '144px'};
    };
`;

export const BlogWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media ${deviceLessThan.tablet}{
        flex-direction: column
    };
`;

import React from 'react'
import PropTypes from 'prop-types'
import {
    USPContainer,
    USPWrapper,
    Cards
} from './Components'
import Heading from '../common/ui/Heading'
import Card from './Card'
import BannerInfo from '../BannerInfo'

const index = props => {
    const {
        data = {}
    } = props

    return (
        <USPContainer>
            <USPWrapper>
    <Heading color='WHITE' margin='0 0 93px 0' sMargin='0 0 40px 0'>{data.title}</Heading>
                <Cards>
                        {
                            data.usp instanceof Array && data.usp.map((item, i) => {
                                return <Card card={item} key={i} />;
                            })
                        }

                </Cards>
                <BannerInfo display='none' sDisplay='flex' sMargin='0 auto'/>
            </USPWrapper>
        </USPContainer>
    )
}

index.propTypes = {

}

export default index

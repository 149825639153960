import React from 'react'
import styled from 'styled-components'
import { deviceLessThan } from '../../styles/breakpoints';

const center_image_pattern = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1600026690/chqbookWeb/0-5kb/mockup-dot-pattern_2x.png';
const product_wrapper_revert_pattern = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1600027118/chqbookWeb/0-5kb/quarter-pattern.png';


export const ProductContainer = styled.div`
    padding: 100px 0 100px 0;
    background-color:${p => p.revert ? 'white' : '#f2f8fe'};
    width: 100%;
    min-height: 570px;
    @media ${deviceLessThan.tablet}{
        padding: 0 20px 20px 20px;
        background: ${p => p.revert && `url(${product_wrapper_revert_pattern}) right top no-repeat`};
        background-size: ${p => p.revert && `auto 99px;`};
    }
`;

export const ProductWrapper = styled.div`
    width: 75%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: ${p => p.revert ? 'row-reverse' : 'row'};
    @media ${deviceLessThan.laptop}{
        width: 95%;
    }
    @media ${deviceLessThan.tablet}{
            flex-direction: column;
    }
`;

export const ContentWrapper = styled.div`
        position: relative;
        width: 50%;
        background: ${p => !p.noBack && `url(${center_image_pattern}) 32% 0% no-repeat`};
        background-size: ${p => !p.noBack && `auto 94px`};
        left: ${p => p.noBack && p.revert && '100px'};
        @media ${deviceLessThan.laptop}{
            left: 0px;
        }
        @media ${deviceLessThan.tablet}{
            background: ${p => !p.noBack && `url(${center_image_pattern}) 15% 0% no-repeat`};
            background-size: ${p => !p.noBack && `auto 94px`};
            width: 100%;
            text-align: center;
        }
`;

export const IconHeading = styled.div`
        display: flex;
        margin: 102px 0 30px 0;
        & > img {
            align-self: center;
        }
        @media ${deviceLessThan.tablet}{
           justify-content: center;
           margin: 54px 0 22px;
    }
`;

export const CenterImageWrapper = styled.div`
        position: relative;
        width: 100%;
`;

export const MockupCircle = styled.div`
        position: absolute;
        width: 380px;
        height: 380px;
        background-color: #eef4fa;
        top: 45px;
        border-radius: 50%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        opacity: ${p => p.revert ? '0.3' : '1'};
        @media ${deviceLessThan.tablet}{
            width: 314px;
            height: 314px;
        }
        @media ${deviceLessThan.mobileM}{
            width: 275px;
            height: 275px;
        }
`;

export const ImageWrapper = styled.div`
        & > img {
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
        }
        @media ${deviceLessThan.tablet}{
            & > img {
                position: relative;
        }
        }
`;

export const TopImageWrapper = styled.div`
        & > img:first-child {
            left: 210px;
            top: 60px;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
        }
        & > img:last-child {
            left: 0;
            top: 260px;
            right: 170px;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
        }

        @media ${deviceLessThan.laptop}{
            & > img:first-child {
            left: 160px;
            width: 150px;
        }
    }

        @media ${deviceLessThan.tablet}{
            & > img:first-child {
            left: unset;
            right: -20px;
            width: 150px;
        }
            & > img:last-child {
            left: -30px;
            right: unset;
        }
    }
`;

export const SectionBottomLine = styled.div`
        position: absolute;
        width: 2px;
        height: 140px;
        background-color: #19447a;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 450px;

        @media ${deviceLessThan.tablet}{
            display: ${props => props.sdisplay}
        }
`;

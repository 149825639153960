import React from 'react'
import PropTypes from 'prop-types'
import { AppDownloadContainer, AppDownload, Wrapper, IconWrapper } from './Components'
import Text from '../common/ui/Text'
import { Image } from '../common/ui/Image'

const ActionButton = ({ getApp = () => { } }) => {

    return (
        <AppDownloadContainer onClick={getApp}>
            <AppDownload>
                <Wrapper>
                    <Text size='xl' weight='600'>Get Chqbook App</Text>
                    <IconWrapper>
                        <Image src='https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1599889224/chqbookWeb/0-5kb/play-store-logo_2x.png'
                            width='15px'
                        />
                        <Image src='https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1599889224/chqbookWeb/0-5kb/app-store_2x.png'
                            width='16px'
                        />
                    </IconWrapper>
                </Wrapper>
            </AppDownload>
        </AppDownloadContainer>
    )
}

ActionButton.propTypes = {

}

export default ActionButton

import React from 'react'
import PropTypes from 'prop-types'
import { GetStartedContainer, GetStartedWrapper } from './Components';
import { Image, ImageContainer } from '../common/ui/Image';
import CustomTextBox from '../common/ui/CustomTextBox';
import Heading from '../common/ui/Heading';
import Text from '../common/ui/Text';
import BadgeWrapper from '../common/ui/BadgeWrapper';
import CutomButton from '../common/ui/CutomButton';
import { HomePageUtility } from '../../utils/services/homepageUtil'

const APP_LINK = 'https://chqbook.app.link/nQGZqUy2L9';

export class GetStarted extends React.Component {
    util = new HomePageUtility(this);

    constructor(props) {
        super();
        this.state = {
            mob: '',
            success: null,
            error: null
        }
        this.setState = this.setState.bind(this)
    }

    onMobileInput = (event) => {
        this.setState({ mob: event.target.value })
    }

    sendLink = () => {
        const {
            mob
        } = this.state;
        this.util.sendSMS({
            mobile: mob
        });
    }

    onBtnClick = () => {
        if (window) {
            window.open(APP_LINK, '_blank');
        }
    }

    render() {
        const {
            error,
            success
        } = this.state;

        const {
            data = {}
        } = this.props

        return (
            <GetStartedContainer>
                <GetStartedWrapper>
                    <div>
                        <ImageContainer
                            display='flex'
                            jContent='center'
                            imgSelfAlign='center'
                            transform='translate(0px, 12px)'
                            margin='0 auto'
                            width='282px'
                        >
                            <Image {...data.imgSrc} />
                        </ImageContainer>
                        <CustomTextBox
                            label='Enter your mobile no.'
                            width='394px'
                            swidth='100%'
                            clicklabel='GET APP LINK'
                            onChange={this.onMobileInput}
                            onClick={this.sendLink}
                            error={error}
                            success={success}
                        />
                    </div>
                    <div>
                        <Heading margin='0 0 20px 0' sMargin='0 0 20px 0' color='MEDIUM_BLACK'>{data.title}</Heading>
                        {/* <Text margin='0 0 50px 0' weight='bold' sMargin='0 48px 44px 48px' color='CHQBOOK_RED'>{data.subtitle}</Text> */}
                        <BadgeWrapper margin='0 auto' sDisplay='none' hiddenMobile='true' appLink={APP_LINK} />
                        <CutomButton
                            display='none'
                            sdisplay='block'
                            width='100%'
                            labelwidth='200px'
                            labelmargin='0 auto'
                            onClick={this.onBtnClick}
                        >
                            {data.btnLabel}
                            <ImageContainer width='13px'>
                                <Image src='/images/icons/playstore-header-cta.png' />
                            </ImageContainer>
                            <ImageContainer width='13px'>
                                <Image src='/images/icons/apple-header-cta.png' />
                            </ImageContainer>
                        </CutomButton>
                    </div>
                </GetStartedWrapper>
            </GetStartedContainer>
        )
    }
}

GetStarted.propTypes = {

}

export default GetStarted

import { post } from '../api'
import { SEND_SMS_API } from '../../constants/apiUrls'

const MOBILE_REGEX = /^[6789]\d{9}$/;

export class HomePageUtility {
    constructor(props) {
        this.component = props;
    }

    sendSMS = (payload) => {
        if (payload.mobile.length == 0 || payload.mobile.length > 10 || !MOBILE_REGEX.test(payload.mobile)) {
            this.component.setState({ error: 'Please enter a valid mobile number', success: null })
            return;
        }
        this.component.setState({ error: null, success: null })
        post(SEND_SMS_API, {
            utmCampaign: 'GetAppLink',
            utmMedium: 'Website_Button',
            utmSource: 'Website',
            ...payload
        })
            .then(res => {
                if (res.status == 200 || res.status == 201) {
                    const msg = res.data.data.message
                    this.component.setState({
                        success: msg,
                        error: null
                    })
                }
            })
            .catch(res => {
                try {
                    if (res.status >= 400 && res.status < 403) {
                        const err = res.data.errors[0];
                        this.component.setState({
                            error: err,
                            success: null
                        })
                    }
                } catch (err) {
                    console.log('send sms api error', err)
                }
            })
    }
}

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import Banner from '../components/Banner'
import USP from '../components/USP'
import Product from '../components/Product'
import GetStarted from '../components/GetStarted'
import MediaMentions from '../components/MediaMentions'
import { getJsonFromString, featuredOrLatestPosts } from '../utils/helpers'
import Blog from '../components/Blog'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/

const Index = ({ data, location, pageContext }) => {
    // const posts = data.allGhostPost.edges

    let bannerData = data.banner.edges[0]?.node.plaintext;
    const sanitizedBannerData = getJsonFromString(bannerData);

    let uspData = data.usp.edges[0]?.node.plaintext;
    const sanitizedUspData = getJsonFromString(uspData);

    let productData = data.product.edges[0]?.node.plaintext;
    const sanitizedProductData = getJsonFromString(productData);

    let getStartedData = data.getStarted.edges[0]?.node.plaintext;
    const sanitizedGetStartedData = getJsonFromString(getStartedData);

    let newsData = data.news.edges[0]?.node.plaintext;
    const sanitizedNewsData = getJsonFromString(newsData);

    let meta = data.meta.edges[0]?.node.plaintext;
    const sanitizedMeta = getJsonFromString(meta);

    let blogMeta = data.blogSectionMeta.nodes[0]?.plaintext;
    const sanitizedBlogMeta = getJsonFromString(blogMeta);

    const featuredBlogArticles = data.featuredBlog.edges;
    const latestPost = data.allLatestPost.edges;

    let featuredPosts = featuredOrLatestPosts(featuredBlogArticles, latestPost, 4)

    return (
        <>
            <MetaData
                location={location}
                data={data}
                keywords={sanitizedMeta?.keywords}
                subject={sanitizedMeta?.subject}
            />
            <Layout isHome={true}>
                <div>
                    <Banner data={sanitizedBannerData ? sanitizedBannerData : []} />
                    <USP data={sanitizedUspData ? sanitizedUspData : {}} />
                    {
                        sanitizedProductData instanceof Array && sanitizedProductData.map((item, i) => {
                            return <Product product={item} key={i} />
                        })
                    }
                    <MediaMentions data={sanitizedNewsData ? sanitizedNewsData : []} />
                    <GetStarted data={sanitizedGetStartedData ? sanitizedGetStartedData : {}} />
                    <Blog blog={{
                        meta: sanitizedBlogMeta,
                        blogs: featuredPosts
                    }}
                        containerMargin="70px auto 0px" />
                </div>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostIndexQuery {

    banner: allGhostPage(
          filter: {
            tags: {
              elemMatch: {
                name: {
                  in:[ "#homepage" ]
                  eq: "#banner"
                }
              }
            }
          }
        ) {
     		edges{
          node{
            plaintext
          }
        }
      }

      usp: allGhostPage(
          filter: {
            tags: {
              elemMatch: {
                name: {
                  in:[ "#homepage" ]
                  eq: "#usp"
                }
              }
            }
          }
        ) {
     		edges{
          node{
            plaintext
          }
        }
      }

      product: allGhostPage(
          filter: {
            tags: {
              elemMatch: {
                name: {
                  in:[ "#homepage" ]
                  eq: "#product-list"
                }
              }
            }
          }
        ) {
     		edges{
          node{
            plaintext
          }
        }
      }

      getStarted: allGhostPage(
          filter: {
            tags: {
              elemMatch: {
                name: {
                  in:[ "#homepage" ]
                  eq: "#get-started"
                }
              }
            }
          }
        ) {
     		edges{
          node{
            plaintext
          }
        }
      }

    meta: allGhostPage(
          filter: {
            tags: {
              elemMatch: {
                name: {
                  in:[ "#homepage" ]
                  eq: "#meta"
                }
              }
            }
          }
        ) {
     		edges{
          node{
            plaintext
          }
        }
      }

    news: allGhostPage(
          filter: {
            tags: {
              elemMatch: {
                name: {
                  eq: "#news"
                }
              }
            }
          }
        ) {
     		edges{
          node{
            plaintext
          }
        }
      }

    featuredBlog:allGhostPost(
            limit: 4,
            filter:{
                featured:{eq: true}
                tags:{elemMatch:{name:{in:[ "#homepage" ]}}}
            }
  	        sort: { order: DESC, fields: [published_at] }
        ){
            edges{
                node{
                ...GhostPostFields
            }
        }
    }

    allLatestPost: allGhostPost(
        limit: 4,
        filter:{featured:{eq: false}}
  	    sort: { order: DESC, fields: [published_at] }
        ){
         edges{
            node{
                ...GhostPostFields
            }
        }
    }

    blogSectionMeta: allGhostPage (
                filter: {
                    tags : { elemMatch : { name: { eq: "#blog" }}}
                }
            ){
                nodes {
                    plaintext
            }
        }
  }
`

import React from 'react'
import styled from 'styled-components'
import { deviceLessThan } from '../../styles/breakpoints';

const backgroundPattern1 = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1599880191/chqbookWeb/0-5kb/pattern-banner-1_2x.png'

/**
* BannerContainer
*
*/

export const BannerContainer = styled.div`
    width: 100%;
    background: url('/images/pattern-banner-2.png') left bottom no-repeat, url('/images/pattern-banner-1.png') right no-repeat;
    background-size: auto 149px, auto 236px;
    /* below tablet breakpoint */
    @media ${deviceLessThan.tablet} {
        padding: 15px 15px 0;
        background: url(${backgroundPattern1}) 100% no-repeat;
        background-size: auto 130px;
        overflow: hidden;
    }
`;

export const BannerWrapper = styled.div`
    width: 75%;
    max-width: 1200px;
    display:block;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    margin-top: 40px;
    & > div:first-child{
        margin-top: 80px;
        width: 50%;
        @media ${deviceLessThan.laptop} {
            margin-top: 150px;
        }
        @media ${deviceLessThan.tablet} {
            width: 100%;
            text-align: center;
            margin-top: 0;
        }
    }
    /* below laptop breakpoint */
        @media ${deviceLessThan.laptop} {
        width: 90%
    }
    /* below tablet breakpoint */
        @media ${deviceLessThan.tablet} {
        width: 100%;
        flex-direction: column;
    }
`;

export const TextBoxWrapper = styled.div`
    max-width: 515px;
    display: flex;
    & > img {
        align-self: center;
        margin-left: 20px;
    }
    @media ${deviceLessThan.tablet} {
        display: none;
    }

`

export const OvalBackground = styled.div`
  position: ${props => props.position ? props.position : 'absolute'};
  width: ${props => props.diameter ? props.diameter : '658px'};
  height: ${props => props.diameter ? props.diameter : '658px'};
  opacity: ${props => props.opacity ? props.opacity : 0.05};
  background-image: linear-gradient(to bottom, #0285d3, #02aae9);
  border-radius: 50%;
  top: ${props => props.top && props.top};
  left: ${props => props.left && props.left};
  right: ${props => props.right && props.right};
  z-index: -2;
        /* below laptop breakpoint */
    @media ${deviceLessThan.laptopM} {
        right: ${props => props.lRight && props.lRight};
    }
      /* below laptop breakpoint */
      @media ${deviceLessThan.laptop} {
        width: ${props => props.mDiameter ? props.mDiameter : '451px'};
        height: ${props => props.mDiameter ? props.mDiameter : '451px'};
    }
          /* below tablet breakpoint */
        @media ${deviceLessThan.tablet} {
            width: ${props => props.sDiameter ? props.sDiameter : '421px'};
            height: ${props => props.sDiameter ? props.sDiameter : '421px'};
            top: ${props => props.sTop && props.sTop};
            left: ${props => props.sLeft && props.sLeft};
            right: ${props => props.sRight && props.sRight};

    }
`;

export const BannerImageWrapper = styled.div`
    position: relative;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    /* below laptop breakpoint */
    @media ${deviceLessThan.tablet} {
        width: 100%;
        justify-content: center;
        // height: 415px;
    }
`;


export const AnimatedWrapper = styled.div`
    align-self: ${props => props.alignSelfContainer};
    min-height:${props => props.minHeight};
    position:${props => props.position};
    width: ${props => props.width ? props.width : '100%'};
    padding:${props => props.padding};
    display:${props => props.display ? props.display : 'block'};
    max-width:${props => props.maxWidth && props.maxWidth};
    margin: ${props => props.margin ? props.margin : '0'};
    height: ${props => props.height && props.height};/* height of container */
    overflow: ${props => props.overflow && props.overflow};
    overflow-y: ${props => props.overflowY && props.overflowY};
    text-align: ${props => props.textAlign && props.textAlign};
    justify-content: ${props => props.jContent && props.jContent};
    flex-direction:${props => props.flexDirection && props.flexDirection};
    cursor:${props => props.cursor};
    flex:${props => props.flex && props.flex};
    background-color:${props => props.backgroundColor ? props.theme.colors[props.backgroundColor] : props.theme.colors.TRANSPARENT};
    border-radius:${props => props.borderRadius && props.borderRadius};
    transition: 0.7s ease;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? 1 : 0};

    @keyframes mymove {
        0%  {
            transform: translate(50px);
            opacity: 1;
            }
        50%  {
            transform: translate(50px);
            opacity: 1;
            }
        100% {
            transform: translate(400px);
            opacity: 0;
            }
        }

    @keyframes yourmove {
        0%   {
            right: 350px;
            opacity: 0;
            }
        50%  {
            right: 350px;
            opacity: 0;
            }
        100% {
            right: 125px;
            opacity: 1;
            }
        }

        @keyframes mymovemobile {
        0%  {
            transform: translate(-80px);
            opacity: 1;
            width: 100%;
            }
        50%  {
            transform: translate(-80px);
            opacity: 1;
            width: 100%;
            }
        100% {
            transform: translate(400px);
            opacity: 0;
            width: 40%;
            }
        }

        @keyframes yourmovemobile {
        0%   {
            transform: translate(-400px);
            opacity: 0;
            }
        50%  {
            transform: translate(-400px);
            opacity: 0;
            }
        100% {
            transform: translate(0px);
            opacity: 1;
            }
        }

        @keyframes playbutton {
        0%   {
            opacity: 0;
            }
        25%  {
            opacity: 0;
            }
        50%  {
            opacity: 0;
            }
        75%  {
            box-shadow: 0 0 16px 0px rgb(0 0 0 / 20%);
            opacity: 0;
            cursor: unset;
            }
        100% {
            box-shadow: 0 0 16px 0px rgb(0 0 0 / 50%);
            opacity: 1;
            cursor: pointer
            }
        }


    & > img:first-child{
        animation: ${props => props.visible && props.classId != 0
        ? props.classId == 1
            ? `mymove 2s forwards` :
            `yourmove 2s forwards`
        : 'unset'};
        position: absolute;
        width: ${props => props.imgWidth};
        left: ${props => props.imgLeft};
        right: ${props => props.imgRight};
        bottom: ${props => props.imgBottom};
        top: ${props => props.imgTop};
        margin: ${props => props.imgMargin};
        @media ${deviceLessThan.laptopM} {
            left: ${props => props.lImgLeft};
            bottom: ${props => props.lImgBottom};
            width:${props => props.lImgWidth};

        }
        @media ${deviceLessThan.laptop} {
            width:${props => props.mImgWidth};
        }
        @media ${deviceLessThan.laptopS} {
            left: ${props => props.sImgLeft};
            bottom: ${props => props.sImgBottom};
            top: ${props => props.sImgTop};
            width:${props => props.sImgWidth};
        }
        @media ${deviceLessThan.tablet} {
            animation: ${props => props.visible && props.classId != 0
        ? props.classId == 1
            ? `mymovemobile 2s forwards` :
            `yourmovemobile 2s forwards`
        : 'unset'};
            right: 0;
            margin-left: auto;
            left: 0;
            margin-right: auto;
            top: 0;
        }
    }

    & > img:nth-child(2){
        animation: ${props => props.visible && props.classId != 0
        ? `playbutton 3s forwards`
        : 'unset'};
        position: absolute;
        width: ${props => props.secondImgWidth};
        background: ${props => props.background};
        left: ${props => props.secondImgLeft};
        right: ${props => props.secondImgRight};
        bottom: ${props => props.secondImgBottom};
        top: ${props => props.secondImgTop};
        margin: ${props => props.imgMargin};
        border-radius: 50%;
        transition: 0.2s ease-in-out;
        & :hover{
            width: 102px;
        }
        @media ${deviceLessThan.laptopM} {
            left: ${props => props.lSecondImgLeft};
            right: ${props => props.lSecondImgRight};
        }
        @media ${deviceLessThan.laptopS} {
            left: ${props => props.sSecondImgLeft};
        }
        @media ${deviceLessThan.tablet} {
            animation: ${props => props.visible && props.classId != 0
        ? `playbutton 3s forwards`
        : 'unset'};
            right: 0;
            margin-left: auto;
            left: 0;
            margin-right: auto;
            top: 30%;
        }
    }
`

import React from 'react';
import {
    BannerContainer,
    OvalBackground,
    BannerImageWrapper,
    BannerWrapper,
    TextBoxWrapper,
    AnimatedWrapper
} from './Components';

import { Image, ImageContainer } from '../common/ui/Image'
import CustomTextBox from '../common/ui/CustomTextBox'
import Heading from '../common/ui/Heading';
import Text from '../common/ui/Text';
import BannerInfo from '../BannerInfo';
import { HomePageUtility } from '../../utils/services/homepageUtil';
import ActionButton from '../ActionButton';
import { ModalContainer } from '../common/Modal/Component';
import Modal from '../common/Modal/index';
import Iframe from '../common/ui/Iframe';
import { playButtonLogo } from '../../constants/strings'

/**
* Banner component
*
*/

class Banner extends React.Component {
    util = new HomePageUtility(this);

    constructor(props) {
        super();
        this.state = {
            mob: '',
            success: null,
            error: null,
            play: false,
            classId: 0
        }
        this.setState = this.setState.bind(this)
    }

    componentDidMount() {
        this.count = setInterval(this._tick, 1500);
    }

    _tick = () => {
        const {
            classId
        } = this.state;
        if (classId < 1) {
            this.setState(prevState => ({
                classId: prevState.classId + 1,
            }));
        }
        else {
            return;
        }
    }

    componentWillUnmount() {
        clearInterval(this.count);
    }

    onMobileInput = (event) => {
        this.setState({ mob: event.target.value })
    }

    sendLink = () => {
        const {
            mob
        } = this.state;
        this.util.sendSMS({
            mobile: mob
        });
    }

    getApp = () => {
        if (window) {
            window.open('https://chqbook.app.link/adQEzb80L9', '_blank');
        }
    }

    setPlay = (bool) => {
        this.setState({ play: bool })
    }

    render() {
        const {
            error,
            success,
            play,
            classId
        } = this.state;
        const {
            data = {}
        } = this.props;

        return (
            <BannerContainer>
                {
                    play &&
                    <Modal
                        show={play}
                        modalClosed={this.setPlay.bind(null, false)}
                    >
                        <ModalContainer
                            width='100%'
                            maxWidth='1080px'
                            left='0'
                            right='0'
                            margin='auto'
                            transform='unset'
                            top='20%'
                            sLeft='0'
                            sRight='0'
                            sTransform='unset'
                        >
                            <div>
                                <Iframe
                                    width="100%"
                                    height="450"
                                    sHeight='350px'
                                    src={data.videoSrc ? data.videoSrc.split(' ')[0] : null}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen={true}
                                ></Iframe>
                            </div>
                        </ModalContainer>
                    </Modal>
                }
                <OvalBackground top='-60px' left='-107px' sTop='188px' sLeft='-137px' />
                <BannerWrapper>
                    <div>
                        <Heading
                            size='xxxl'
                            family='fontFamilyBold'
                            weight='bold'
                            lineHeight='1.24'
                            spacing='-1px'
                        >{data.headingTop}
                        </Heading>
                        <Heading
                            size='xxxl'
                            family='fontFamilyBold'
                            weight='bold'
                            lineHeight='1.5'
                            spacing='-1px'
                        >{data.headingBottom}</Heading>
                        <br />
                        <Text
                            size='xxl'
                            lineHeight='1.7'
                            weight='medium'
                            color='MEDIUM_BLACK'
                            sOpacity='0.8'
                        >{data.subHeading}</Text>
                        <br />
                        <TextBoxWrapper>
                            <CustomTextBox
                                label='Enter your mobile no.'
                                width='475px' clicklabel='GET APP LINK'
                                onChange={this.onMobileInput}
                                onClick={this.sendLink}
                                error={error}
                                success={success}
                            />
                            <Image src='https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1599889224/chqbookWeb/0-5kb/play-store-logo_2x.png' width='30px' />

                            {/* <Image src='https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1599889224/chqbookWeb/0-5kb/app-store_2x.png' width='30px' /> */}
                        </TextBoxWrapper>
                        <br />
                        <BannerInfo sDisplay='none' margin='25px 0 0 0' />
                    </div>
                    <BannerImageWrapper>
                        <OvalBackground
                            diameter='451px'
                            opacity='0.03'
                            top='125px'
                            right='55px'
                            mDiameter='291px'
                            sDiameter='160px'
                            sTop='-240px'
                            sRight='-110px'
                            lRight='-50px'
                        />
                        {/* <AnimatedWrapper
                            visible={classId == 0}
                            classId={0}
                            height='640px'
                            overflowY='hidden'
                            width='470px'
                            mWidth='370px'
                            sWidth='312px'
                            sHeight='420px'
                            imgRight='0px'
                            lImgBottom='0px'
                            lImgWidth='350px'
                        >
                            <Image
                                {...data.centerImageOne}
                            />
                        </AnimatedWrapper> */}
                        {/* <AnimatedWrapper
                            visible={classId == 1}
                            classId={1}
                            height='640px'
                            overflowY='hidden'
                            width='470px'
                            mWidth='370px'
                            sWidth='312px'
                            sHeight='420px'
                            imgRight='0px'
                            imgTop='-5px'
                            sImgBottom='0px'
                            sImgTop='unset'
                            mImgWidth='386px'
                        >
                            <Image
                                {...data.centerImageTwo}
                            />
                        </AnimatedWrapper> */}
                        {/* <AnimatedWrapper
                            visible={classId == 1}
                            classId={2}
                            height='640px'
                            overflowY='hidden'
                            width='470px'
                            mWidth='370px'
                            sWidth='312px'
                            sHeight='420px'
                            imgBottom='-15px'
                            // imgWidth='300px'
                            imgRight='125px'
                            lImgLeft='90px'
                            sImgLeft='25px'
                            sImgWidth='260px'
                            secondImgWidth='100px'
                            secondImgTop='50%'
                            secondImgRight='220px'
                            lSecondImgLeft='190px'
                            sSecondImgLeft='110px'
                            background='gainsboro'
                        > */}
                            <Image
                                // style={{ marginBottom: '-20px' }}
                                {...data.centerImageThree}
                            />
                            {/* <Image
                                onClick={this.setPlay.bind(null, true)}
                                src={playButtonLogo}
                            /> */}
                        {/* </AnimatedWrapper> */}
                    </BannerImageWrapper>
                </BannerWrapper>
                <ActionButton getApp={this.getApp} />
            </BannerContainer>
        )
    }
}


Banner.defaultProps = {
}

Banner.propTypes = {
}

export default Banner

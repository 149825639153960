import React from 'react'
import styled from 'styled-components'
import { deviceLessThan } from '../../styles/breakpoints';

/**
* BannerContainer
*
*/

export const FootText = styled.div`
        display: ${ props => props.display ? props.display: 'flex'};
        justify-content: space-between;
        width: 384px;
        margin: ${ props => props.margin };

    @media ${deviceLessThan.laptop} {
        width: 300px;
    }

    /* below tablet breakpoint */
    @media ${deviceLessThan.tablet} {
        display: ${ props => props.sDisplay && props.sDisplay};
        justify-content: ${ props => props.sJContent ? props.sJContent : "space-between"};
        width: 95%;
        margin: ${props => props.sMargin};
    }
`;

export const Block = styled.div`
        display: flex;
        flex-direction: column;
`;

export const BlockRow = styled.div`
        display: flex;
        margin-bottom: 5px;
        justify-content:${props => props.jContent};
        & > div:first-child{
            margin-right: 10px;
            align-self: center;
        }
`;

/**
 *
 * Iframe
 *
 */

import React from 'react';
import styled from 'styled-components';
import { deviceLessThan, deviceGreaterThan } from '../../../styles/breakpoints';

const Iframe = styled.iframe`
  width:${p => p.width && p.width };
  height:${p => p.height};
  @media ${deviceLessThan.tablet} {
    width:${p => p.sWidth && p.sWidth };
    height:${p => p.sHeight};
}
`

export default Iframe;

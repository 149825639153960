import React from 'react'
import styled from 'styled-components'
import { deviceLessThan } from '../../styles/breakpoints';

const bg_pattern = "https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1600550082/chqbookWeb/less50kb/second-fold-pattern_2x.png"

export const USPContainer = styled.div`
    position: relative;
    width: 100%;
    min-height: 550px;
    //[@info: here 80% is added since radial-gradient was filling up the whole container on ios chrome and safari devices]
    background: radial-gradient(85% 51% at 51% 10%, #e82e2f 100%, white 44% 80%);
    @media ${deviceLessThan.tablet}{
        background: radial-gradient(100% 51% at 51% -17%,#e82e2f 100%,white 44% 80%);
    }
`;

export const USPWrapper = styled.div`
        background: url(${bg_pattern}) left top no-repeat;
        background-size: auto 272px;
        text-align: center;
        padding-top: 70px;

        @media ${deviceLessThan.tablet}{
            padding: 50px 20px 20px 20px;
            background-size: auto 150px;
        }
`

export const Cards = styled.div`
        display: flex;
        justify-content: space-around;
        width: 75%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 5px;
        @media ${deviceLessThan.laptop} {
            width: 95%;
        }
        @media ${deviceLessThan.tablet} {
            width: 100%;
            flex-direction: column;
        }
`;

export const CardWrapper = styled.div`
    border-radius: 6px;
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: 20%;
    max-width: 222px;
    min-height: 266px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    & > div:last-child {
            min-height: 145px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
    }
    @media ${deviceLessThan.laptop} {
            width: 23%;
    }
    @media ${deviceLessThan.tablet} {
            flex-direction: row;
            min-height: 0;
            width: 100%;
            max-width: 100%;
            margin: 18px 0;
            padding: 14px 14px 14px 24px;
            justify-content: flex-start;
            & > div:last-child {
                margin: 0 14px 0 19px;
                min-height: 0px;
                align-self: center;
                width: 70%;
            }
    }
`

export const CardExcerpt = styled.div`
        border: 2px solid #E82E2F;
        border-radius: 40px;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #E82E2F;
        padding: 4px 8px 4px 8px;
        align-self: center;

        @media ${deviceLessThan.tablet} {
            align-self: flex-start;
    }
`;


import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField';
import InfoIcon from '@material-ui/icons/Info';
import Text from './Text';
import { deviceLessThan } from '../../../styles/breakpoints';

const TextBox = styled(({ notchedOutlinedWidth, ...otherProps }) => (
    <TextField
        {...otherProps}
        variant="outlined"
        InputProps={{ style: { fontSize: '16px' } }}
    />))`
        width: 100%;
        background-color: ${props => props.theme.colors.WHITE};
        margin-top: 10px;

        & .MuiInputLabel-outlined, .MuiInputBase-input{
            font-family: ${p => p.theme.fontFamily};
            font-size: 16px;
        }

    @media ${deviceLessThan.laptop} {
        & .MuiInputLabel-outlined, .MuiInputBase-input{
            font-size: 15px;
        }
    }
`;


const TextBoxContainer = styled.div`
  width: ${props => props.width && props.width};
  position: relative;
  display: flex;
  flex-direction: column;
  @media ${deviceLessThan.tablet}{
    width: ${props => props.swidth};
  }
`;

const InfoWrapper = styled.div`
    padding: 0 10px;
    display: flex;
    margin: 5px 0;
    flex-direction: row;
    align-items: center;
    background: ${props => props.backgroundColor && props.theme.colors[props.backgroundColor]};
`;

const CustomTextBox = ({ onClick, error, success, ...otherProps }) => {
    return (
        <>
            <TextBoxContainer width={otherProps.width} swidth={otherProps.swidth}>
                <TextBox {...otherProps} />
                {otherProps.clicklabel &&
                    <Text position='absolute'
                        top='27px'
                        right='10px'
                        size='xs'
                        weight='bold'
                        zIndex='1'
                        background='white'
                        cursor='pointer'
                        onClick={onClick}
                    >{otherProps.clicklabel}</Text>
                }
                {
                    error &&
                    <InfoWrapper backgroundColor='ERROR_MESSAGE'>
                        <InfoIcon style={{ fontSize: '16px', marginRight: '5px' }} />
                        <Text>{error}</Text>
                    </InfoWrapper>
                }
                {
                    success &&
                    <InfoWrapper backgroundColor='SUCCESS_MESSAGE'>
                        <InfoIcon style={{ fontSize: '16px', marginRight: '5px' }} />
                        <Text>{success}</Text>
                    </InfoWrapper>
                }
            </TextBoxContainer>
        </>
    )
}

export default CustomTextBox;


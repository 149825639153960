import axios from 'axios';

export const post = (url, body) => {
    const finalUrl = process.env.API_BASE + url;
    return new Promise((resolve, reject) => {
      axios
        .post(finalUrl, body)
        .then(response => resolve(response))
        .catch(error => reject(error.response));
    });
  };

import React from 'react'
import styled from 'styled-components'
import { deviceLessThan } from '../../styles/breakpoints';


export const MediaContainer = styled.div`
    padding: 50px 0px 40px 0px;
    @media ${deviceLessThan.laptop} {
        padding: 50px 0 0 0;
    }
`;

export const MediaWrapper = styled.div`
    width: 75%;
    margin: 0 auto;

    @media ${deviceLessThan.laptopL} {
        width: 90%
    }

    @media ${deviceLessThan.laptop} {
        width: 90%
    }
`;

export const MediaCards = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media ${deviceLessThan.laptop} {
        flex-direction: column;
    }
`;

export const MediaCardContainer = styled.div`
    padding: 31px 35px 20px 22px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: 30%;
    max-width: 300px;
    min-width: 300px;
    margin: 20px;

    & > div:nth-child(2){
        width: 60px;
        height: 2px;
        opacity: 0.3;
        background-color: #d8d8d8;
        margin-bottom: 20px;
        @media ${deviceLessThan.laptop} {
            margin-bottom: 7px;
        }
    }

    @media ${deviceLessThan.laptop} {
        max-width: 100%;
        margin: 0 auto;
        width: 60%;
        margin-bottom: 16px;
        padding: 15px 25px 17px 12px;
    }

    @media ${deviceLessThan.tablet} {
       width: 100%;
    }
`;

export const LogoDateContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${deviceLessThan.laptop} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    & > div:first-child{
        margin-bottom: 23px;
        min-height: 75px;
        display: flex;
        @media ${deviceLessThan.laptop} {
            margin-bottom: 5px;
            min-height: 0;
            width: fit-content;
        }
    }
`;

export const ReadMore = styled.span`
    & > a {
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;
        color: #111111;
    }
`;
